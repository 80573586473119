import * as React from 'react';
import {Box, Container, Stack, Typography} from "@mui/material";

export default function AppHero() {
    return (
        <Container maxWidth={"md"}>
            <Box pb={4} pt={4}>
                <Stack direction={"row"} spacing={"2"} justifyContent={"center"} alignItems={"center"}>
                    <Box m={2} width={"3/4"}>
                        <Typography variant={"h2"}>
                            Willkommen bei GroupMate
                        </Typography>
                        <Typography pt={5}>
                            GroupMate hilft dir, bei Veranstaltungen einen Überblick über die Teilnehmer zu behalten.
                        </Typography>
                        <Box pt={4}>
                            <Stack direction={"row"} spacing={"2"} justifyContent={"center"} alignItems={"center"}>
                                <a href={"https://apps.apple.com/us/app/mygroupmate/id1490590795"} target={"_blank"}>
                                    <Box
                                        component={"img"}
                                        src={"/download-appstore.svg"}
                                        sx={{height: 40}}
                                        margin={"10px"}
                                    />
                                </a>
                                <a href={"https://play.google.com/store/apps/details?id=com.groupmate.android"} target={"_blank"}>
                                    <Box
                                        component={"img"}
                                        src={"/download-playstore.svg"}
                                        sx={{height: 41}}
                                        margin={"10px"}
                                    />
                                </a>
                            </Stack>
                        </Box>
                    </Box>
                    <Box m={2} width={"1/4"} pl={5}>
                        <Box
                            component={"img"}
                            src={"/screenshot-iphone-events.png"}
                            sx={{height: 600}}
                        />
                    </Box>
                </Stack>
            </Box>
        </Container>
    )
}