import * as React from 'react';
import Copyright from '../components/Copyright';
import PageHeader from "../components/PageHeader";
import AppHero from "../components/AppHero";
import PageDefault from "../components/PageDefault";

export default function Index() {
    return (
        <>
            <PageDefault/>
            <PageHeader/>
            <AppHero/>
            <Copyright/>
        </>
    );
}
